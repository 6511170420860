var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "image-box" }, [
                item.coinPercentage
                  ? _c("div", { staticClass: "cut-img-rate" }, [
                      _c("span", [_vm._v("识别概率：")]),
                      _vm._v(_vm._s(item.coinPercentage))
                    ])
                  : _vm._e(),
                _c("img", {
                  staticClass: "image",
                  attrs: {
                    src: item.cutFrontImage || item.abbreviationFrontImage
                  },
                  on: {
                    click: function($event) {
                      return _vm.previewImg(index)
                    }
                  }
                }),
                _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(item.id))]),
                _c(
                  "div",
                  { staticClass: "check-box" },
                  [
                    _c("a-checkbox", {
                      staticClass: "checked",
                      model: {
                        value: item.checkStatus,
                        callback: function($$v) {
                          _vm.$set(item, "checkStatus", $$v)
                        },
                        expression: "item.checkStatus"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(item.coinKindName) + " ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.moveClick(item)
                      }
                    }
                  },
                  [_vm._v("挪动")]
                ),
                _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: "确定要删除此项么？",
                      "ok-text": "确认",
                      "cancel-text": "取消"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.handleRemove(item.id)
                      }
                    }
                  },
                  [_c("a", [_vm._v("删除")])]
                )
              ],
              1
            )
          ])
        }),
        0
      ),
      _c("TrainMove", { ref: "train-move", on: { success: _vm.editSuccess } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }