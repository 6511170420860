<template>
  <div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="content">
          <div class="image-box">
            <div class="cut-img-rate" v-if="item.coinPercentage"><span>识别概率：</span>{{ item.coinPercentage }}</div>
            <img
                  class="image"
                  :src="item.cutFrontImage || item.abbreviationFrontImage"
                  @click="previewImg(index)"
            />
            <div class="tip">{{item.id}}</div>
            <div class="check-box">
              <a-checkbox class="checked" v-model:checked="item.checkStatus"></a-checkbox>
            </div>
          </div>
          <div class="name">
            {{item.coinKindName}}
          </div>
        </div>
        <div class="footer">
          <a
            class="move-btn"
            @click="moveClick(item)"
          >挪动</a>
          <a-popconfirm
              title="确定要删除此项么？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleRemove(item.id)"
          >
            <a>删除</a>
          </a-popconfirm>
        </div>
      </div>
    </div>
    <TrainMove
      ref="train-move"
      @success="editSuccess"
    ></TrainMove>
  </div>

</template>

<script>
import TrainMove from "@/views/cmsPage/versionManage/duibanCoinTrain/TrainMove";
export default {
  props: {
    list: {
      default: [],
    },
  },
  components: {
    TrainMove,
  },
  methods: {
    /** 删除 */
    handleRemove(id) {
      this.axios("/dq_admin/duibanCoinTrain/deleteByTrainId", {
        params: { trainIds: id + '' },
      }).then((res) => {
        this.$emit("deleteSuccess", id);
        this.$message.success("删除成功");
      });
    },
    editSuccess(trainId) {
      this.$emit("editSuccess", trainId);
    },
    moveClick(item) {
      this.$refs["train-move"].show(item.id + '');
    },
    // 预览
    previewImg(index) {
      const list = this.list.map((el) => {
        return {img_url: el.originalFrontImage}
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .tool-image-item {
          width: 300px;
          height: 300px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
        .image {
          width: 300px;
          height: 300px;
          border-radius: 4px;
        }
        .check-box {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          .checked {
            width: 70px;
            height: 70px;
            label {
              span {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .cut-img-rate {
          position: absolute;
          left: 0;
          top: 0;
          min-width: 80px;
          padding: 0 10px;
          height: 20px;
          color: dodgerblue;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
          span {
            font-size: 12px;
            color: #0f0f0f;
          }
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
      }

      .name,
      .score {
        text-align: center;
        margin-top: 2px;
      }
    }
    .footer {
      margin-top: 2px;
      a {
        color: red;
        margin-right: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .move-btn {
        color: #1890ff;
      }
    }
  }
}
</style>